<template>
  <div class="doctor-main-component w-full grid grid-cols-1 gap-4">
    <are-you-sure
      :trigger="toogle"
      @toggle="
        event => {
          toogle = false;
          event == 'save' ? save() : '';
        }
      "
    />
    <div class="w-full">
      <div class="w-full mb-4 grid grid-cols-6 gap-y-1 gap-x-2">
        <label
          class="col-span-6 mb-8 block text-md text-left font-bold text-gray-900"
        >
          Datos del doctor
        </label>
        <div class="col-span-6 mb-4 flex flex-row justify-start items-center">
          <div
            class="bg-cover bg-center overflow-hidden h-32 w-32 rounded-full md:rounded-md mb-2 md:mb-0 border border-gray-200 md:mr-4"
            :style="
              doctor && doctor.doctor && doctor.doctor.avatar
                ? 'background-image: url(' + doctor.doctor.avatar_url + ')'
                : ''
            "
            :class="
              doctor && doctor.doctor && doctor.doctor.avatar
                ? ''
                : 'flex justify-center items-center bg-gray-200'
            "
            alt=""
          >
            <span
              class="font-bold text-2xl text-gray-600"
              v-if="doctor && doctor.doctor && !doctor.doctor.avatar"
              >{{ doctor.names[0] }} {{ doctor.lastnames[0] }}</span
            >
          </div>
          <!-- <div
            class="bg-cover bg-center overflow-hidden h-14 w-14 rounded-full md:rounded-md mb-2 md:mb-0 border border-gray-200 md:mr-4"
            :style="
              doctor && doctor.doctor && doctor.doctor.logo
                ? 'background-image: url(' + doctor.doctor.logo_url + ')'
                : ''
            "
            :class="
              doctor && doctor.doctor && doctor.doctor.logo
                ? ''
                : 'flex justify-center items-center bg-gray-200'
            "
            alt=""
          >
            <span
              class="font-bold text-md text-gray-600"
              v-if="doctor && doctor.doctor && !doctor.doctor.logo"
              >LG</span
            >
          </div> -->
          <div class="flex flex-col justify-center items-start">
            <label
              class="col-span-6 block text-md text-left font-medium text-gray-800"
            >
              Doctor
            </label>
            <label
              v-if="doctor"
              class="col-span-6 block text-sm text-left font-medium text-gray-600 capitalize"
            >
              {{ doctor.names }} {{ doctor.lastnames }}
            </label>
            <span
              class="h-4 mb-2 bg-gray-300 rounded w-40 animate-pulse"
              v-else
            ></span>
          </div>
        </div>
        <div class="col-span-6 w-full flex flex-col justify-start item-start">
          <label
            class="w-full text-left block text-md text-left font-medium text-gray-700"
          >
            Imagen de perfil
          </label>
          <!-- <label
            class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
            adipisci facilis, deleniti repellendus nisi voluptatibus.
          </label> -->
        </div>
      </div>
      <div class="w-full border-b border-gray-300 pb-8 grid grid-cols-3 mb-4">
        <div class="col-span-1 flex flex-row items-center">
          <button
            type="button"
            @click.prevent="selectFile()"
            class="w-full mr-2 justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none sm:text-sm"
          >
            <span v-if="fileAvatar != ''">Seleccionar otro archivo</span>
            <span v-else>Seleccionar archivo</span>
          </button>
        </div>
        <div class="col-span-2 input-group flex flex-row items-center">
          <div
            v-show="showPreview"
            class="card-img-preview mb-4"
            :style="{ backgroundImage: 'url(' + avatarPreview + ')' }"
          ></div>
          <div
            class="border-2 border-dotted border-gray-400 rounded-md w-full py-2 text-gray-800"
          >
            <input
              id="avatarInput"
              type="file"
              name="avatar"
              class="hidden"
              ref="AvatarFile"
              accept="image/*"
              @change="profileAvatar"
            />
            <label v-if="fileAvatar != ''" class="" for="avatarInput">{{
              fileAvatar.name
            }}</label>
            <label v-else class="text-gray-800" for="avatarInput"
              >ó arrastrar aquí</label
            >
          </div>
        </div>
      </div>
      <!-- <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Imagen de Logo
      </label>
      <label
        class="w-full mb-4 text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label>
      <div class="w-full border-b border-gray-300 pb-8 grid grid-cols-3 mb-4">
        <div class="col-span-1 flex flex-row items-center">
          <button
            type="button"
            @click.prevent="selectFileLogo()"
            class="w-full mr-2 justify-center items-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none sm:text-sm"
          >
            <span v-if="fileLogo != ''">Seleccionar otro archivo</span>
            <span v-else>Seleccionar archivo</span>
          </button>
        </div>
        <div class="col-span-2 input-group flex flex-row items-center">
          <div
            v-show="showPreviewLogo"
            class="card-img-preview mb-4"
            :style="{ backgroundImage: 'url(' + logoPreview + ')' }"
          ></div>
          <div
            class="border-2 border-dotted border-gray-400 rounded-md w-full py-2 text-gray-800"
          >
            <input
              id="logoInput"
              type="file"
              name="logo"
              class="hidden"
              ref="LogoFile"
              accept="image/*"
              @change="profileLogo"
            />
            <label v-if="fileLogo != ''" class="" for="logoInput">{{
              fileLogo.name
            }}</label>
            <label v-else class="text-gray-800" for="logoInput"
              >ó arrastrar aquí</label
            >
          </div>
        </div>
      </div> -->
      <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Tarifa por hora
      </label>
      <!-- <label
        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label> -->
      <input
        v-model="rate"
        type="number"
        min="10"
        required
        class="appearance-none mb-4 relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
        placeholder="Valor"
      />
      <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Nombre de doctor para la url/subdominio
      </label>
      <!-- <label
        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label> -->
      <input
        v-model="name"
        type="text"
        required
        class="appearance-none mb-4 relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
        placeholder="Valor"
      />
      <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Especialidad
      </label>
      <!-- <label
        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label> -->
      <input
        v-model="specialty"
        type="text"
        required
        class="appearance-none mb-4 relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
        placeholder="Valor"
      />
      <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Dirección de la consulta
      </label>
      <!-- <label
        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label> -->
      <input
        v-model="address"
        type="text"
        required
        class="appearance-none mb-4 relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
        placeholder="Dirección"
      />
      <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Precios de las reservas
      </label>
      <!-- <label
        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label> -->
      <textarea
        name="note"
        rows="5"
        v-model="pricing"
        class="shadow-sm h-50 mb-4 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
      ></textarea>
      <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Enlace al linkedin
      </label>
      <!-- <label
        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label> -->
      <input
        v-model="linkedin"
        type="text"
        required
        class="appearance-none mb-4 relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
        placeholder="Dirección"
      />
      <div class="w-full flex flex-row justify-start items-center mb-1">
        <div class="flex flex-col justify-start items-start">
          <label
            class="w-full text-left block text-md text-left font-medium text-gray-700"
          >
            Enlace para las videollamadas
          </label>
          <label
            class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
          >
            Enlace que se abrirá cuando presiones "Iniciar videollamada".
          </label>
        </div>
      </div>
      <div class="w-full grid grid-cols-1 gap-x-2">
        <input
          v-model="meeting_url"
          name="meeting_url"
          type="text"
          autocomplete="meeting_url"
          class="mb-2 appearance-none relative block w-full px-3 py-2 border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
          placeholder="Enlace de videollamada"
        />
      </div>
      <label
        class="w-full text-left block text-md text-left font-medium text-gray-700"
      >
        Descripción del perfil de doctor
      </label>
      <!-- <label
        class="w-full text-left block text-sm text-left font-light text-gray-600 text-sm"
      >
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
        adipisci facilis, deleniti repellendus nisi voluptatibus.
      </label> -->
      <textarea
        name="note"
        rows="5"
        v-model="description"
        class="shadow-sm h-50 mb-4 resize-none focus:ring-green-500 focus:border-green-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
      ></textarea>
      <div class="w-full flex justify-end items-center">
        <button
          type="button"
          @click="
            () => {
              toogle = true;
            }
          "
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
        >
          Guardar datos
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DoctorService from '@/services/doctorService';
import AreYouSure from '@/components/modals/AreYouSure.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Settings',
  components: {
    'are-you-sure': AreYouSure,
  },
  data() {
    return {
      doctor: null,
      rate: 0,
      name: '',
      toogle: false,
      specialty: '',
      address: '',
      pricing: '',
      description: '',
      showPreview: false,
      showPreviewLogo: false,
      linkedin: '',
      fileAvatar: '',
      avatarPreview: '',
      fileLogo: '',
      logoPreview: '',
      meeting_url: '',
    };
  },
  created() {
    this.doctor = null;
    this.getDoctor();
  },
  methods: {
    ...mapActions('loading', ['change']),
    getDoctor() {
      DoctorService.getDoctor(this.doctorprop.doctor.name)
        .then(response => {
          this.doctor = response.data;
          if (this.doctor.doctor) {
            if (this.doctor.doctor.rate) {
              this.rate = this.doctor.doctor.rate;
            }
            if (this.doctor.doctor.specialty) {
              this.specialty = this.doctor.doctor.specialty;
            }
            if (this.doctor.doctor.address) {
              this.address = this.doctor.doctor.address;
            }
            if (this.doctor.doctor.prices) {
              this.pricing = this.doctor.doctor.prices;
            }
            if (this.doctor.doctor.description) {
              this.description = this.doctor.doctor.description;
            }
            if (this.doctor.doctor.linkedin) {
              this.linkedin = this.doctor.doctor.linkedin;
            }
            if (this.doctor.doctor.meeting_url) {
              this.meeting_url = this.doctor.doctor.meeting_url;
            }
          }

          if (this.doctor.superdoctor) {
            if (this.doctor.superdoctor.rate) {
              this.rate = this.doctor.superdoctor.rate;
            }
            if (this.doctor.superdoctor.specialty) {
              this.specialty = this.doctor.superdoctor.specialty;
            }
            if (this.doctor.superdoctor.address) {
              this.address = this.doctor.superdoctor.address;
            }
            if (this.doctor.superdoctor.prices) {
              this.pricing = this.doctor.docsuperdoctortor.prices;
            }
            if (this.doctor.superdoctor.description) {
              this.description = this.doctor.superdoctor.description;
            }
            if (this.doctor.superdoctor.linkedin) {
              this.linkedin = this.doctor.superdoctor.linkedin;
            }
            if (this.doctor.superdoctor.meeting_url) {
              this.meeting_url = this.doctor.superdoctor.meeting_url;
            }
          }

          if (this.doctor.psychologist) {
            if (this.doctor.psychologist.rate) {
              this.rate = this.doctor.psychologist.rate;
            }
            if (this.doctor.psychologist.specialty) {
              this.specialty = this.doctor.psychologist.specialty;
            }
            if (this.doctor.psychologist.address) {
              this.address = this.doctor.psychologist.address;
            }
            if (this.doctor.psychologist.prices) {
              this.pricing = this.doctor.psychologist.prices;
            }
            if (this.doctor.psychologist.description) {
              this.description = this.doctor.psychologist.description;
            }
            if (this.doctor.psychologist.linkedin) {
              this.linkedin = this.doctor.psychologist.linkedin;
            }
            if (this.doctor.psychologist.meeting_url) {
              this.meeting_url = this.doctor.psychologist.meeting_url;
            }
          }
        })
        .catch(() => {
          console.log('error getting doctor data');
        });
    },
    selectFile() {
      if (this.$refs.AvatarFile) {
        this.$refs.AvatarFile.click();
      }
    },
    selectFileLogo() {
      if (this.$refs.LogoFile) {
        this.$refs.LogoFile.click();
      }
    },
    profileLogo() {
      if (this.$refs.LogoFile.files[0].size >= 2000000) {
        Notification.fire({
          type: 'error',
          title: 'Imagen demasiado Grande!',
        });
      } else {
        this.fileLogo = this.$refs.LogoFile.files[0];
        var reader = new FileReader();
        reader.addEventListener(
          'load',
          function() {
            this.showPreviewLogo = true;
            this.logoPreview = reader.result;
          }.bind(this),
          false
        );
        if (this.fileLogo) {
          if (/\.(jpe?g|png|gif)$/i.test(this.fileLogo.name)) {
            reader.readAsDataURL(this.fileLogo);
          }
        }
      }
    },
    profileAvatar() {
      if (this.$refs.AvatarFile.files[0].size >= 2000000) {
        Notification.fire({
          type: 'error',
          title: 'Imagen demasiado Grande!',
        });
      } else {
        this.fileAvatar = this.$refs.AvatarFile.files[0];
        var reader = new FileReader();
        reader.addEventListener(
          'load',
          function() {
            this.showPreview = true;
            this.avatarPreview = reader.result;
          }.bind(this),
          false
        );
        if (this.fileAvatar) {
          if (/\.(jpe?g|png|gif)$/i.test(this.fileAvatar.name)) {
            reader.readAsDataURL(this.fileAvatar);
          }
        }
      }
    },
    save() {
      if (this.rate < 0) {
        return;
      }
      this.change(true);
      DoctorService.updateDoctorAdmin(this.doctorprop.id, {
        rate: this.rate,
        name: this.name,
        specialty: this.specialty,
        address: this.address,
        description: this.description,
        prices: this.pricing,
        linkedin: this.linkedin,
        logo: this.fileLogo,
        avatar: this.fileAvatar,
        meeting_url: this.meeting_url,
      })
        .then(() => {
          this.change(false);
          this.getDoctor();
          this.showPreview = false;
          this.showPreviewLogo = false;
          this.fileAvatar = '';
          this.avatarPreview = '';
          this.fileLogo = '';
          this.logoPreview = '';
          console.log('ok');
          this.$Success.fire({
            title: 'La información se ha guardado correctamente',
          });
        })
        .catch(() => {
          console.log('error saving doctor data');
        });
    },
  },
  watch: {
    rate() {
      if (this.rate < 0) {
        this.rate = 0;
      }
    },
  },
  computed: {
    ...mapGetters({
      doctorprop: ['admin/user'],
    }),
  },
};
</script>

<style scoped>
.doctor-main-component {
  min-height: 80vh;
}
.card-img-preview {
  width: 100px;
  height: 100px;
  border-radius: 0.25em;
  margin-right: 10px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-size: cover;
  background-position: center center;
}
.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.custom-file-label {
  border-radius: 0.25em !important;
  overflow: hidden !important;
  border: 1px solid #f2f2f2 !important;
  color: #dedede !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
